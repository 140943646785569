import React, { useState } from "react";
import { CategoryAllProducts, ScreenTopBanner } from "../components";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const ViewAll = () => {
  const [limit, setLimit] = useState(10);
  let { product } = useSelector((product) => product.product);
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  console.log("PRODUCTVIEWALL", product);
  const { categoryId } = useParams();
  console.log("categoryId:", categoryId);

  const filteredProducts = product.data?.filter(
    (product) => product.id == categoryId
  );
  console.log("filteredProducts:", filteredProducts);

  const handleLoadMore = () => {
    setLimit((prevLimit) => prevLimit + 10);
  };
  return (
    <section className="container-fluid" id="jtc-token">
      <ScreenTopBanner title={detectLang(filteredProducts[0], "name", lang)} />
      <section className="product-details-page-three">
        <div className="container-fluid">
          {filteredProducts.map((data, index) => {
            return (
              <CategoryAllProducts
                whereIsUsed={"false"}
                index={index}
                data={data}
                key={index}
                className={"col-lg-4 col-md-6 col-12"}
                limit={limit}
                onLoadMore={handleLoadMore}
              />
            );
          })}
        </div>
      </section>
    </section>
  );
};

export default ViewAll;
