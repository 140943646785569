// import React, { useEffect, useState } from "react";
// import HomeScreen from "./HomeScreen";
// import { StoreLocatorModal } from "../components";
// import { useSelector } from "react-redux";

// const Home = () => {
//   const [storeLocator, setStoreLocator] = useState(false);
//   const { stores } = useSelector((store) => store.store);
//   console.log("STORESS LENGTH CHECK", stores);


 
//   useEffect(() => {
//     if (!stores || (typeof stores === 'object' && Object.keys(stores).length === 0)) {
//       setStoreLocator(true);
//     } else {
//       setStoreLocator(false); 
//     }
//   }, [stores]);
//   return (
//     <React.Fragment>
    
//       {/* <HomeScreen />
//       <StoreLocatorModal
//         storeLocator={storeLocator}
//         setStoreLocator={setStoreLocator}
//       /> */}


      
//     </React.Fragment>
//   );
// };

// export default Home;
import React, { useEffect, useState } from 'react';

const MaintenancePage = () => {
    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        // Check if endTime is already in localStorage
        let endTime = localStorage.getItem('endTime');
        
        // If endTime is not in localStorage, set it for the first time
        if (!endTime) {
            endTime = new Date().getTime() + 72 * 60 * 60 * 1000; // 72 hours from now
            localStorage.setItem('endTime', endTime); // Save it to localStorage
        }

        const countdown = setInterval(() => {
            const now = new Date().getTime();
            const distance = endTime - now;

            if (distance < 0) {
                clearInterval(countdown);
                setTimeLeft({
                    days: 0,
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                });
                localStorage.removeItem('endTime'); // Optional: clear endTime after countdown ends
            } else {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setTimeLeft({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(countdown); // Cleanup interval on component unmount
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div className="maintenance-page">
            <div className="logo-container">
                <img src="/assets/images/working.png" alt="Logo" className="logo" />
            </div>
            <h2>This Site is Under Maintenance</h2>
            <div className="countdown">
                <div className="time-box">
                    <span>{timeLeft.days}</span>
                    <p>DAYS</p>
                </div>
                <div className="time-box">
                    <span>{timeLeft.hours}</span>
                    <p>HOURS</p>
                </div>
                <div className="time-box">
                    <span>{timeLeft.minutes}</span>
                    <p>MINUTES</p>
                </div>
                <div className="time-box">
                    <span>{timeLeft.seconds}</span>
                    <p>SECONDS</p>
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;
