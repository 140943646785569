import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import apis from "../services";
import { logout } from "../redux/slice/userSlice";
import { clearStores } from "../redux/slice/StoreSlice";

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutAndRedirect = async () => {
      try {
        await apis.authLogout();
        dispatch(logout());
        dispatch(clearStores());
        window.location.href = "/login";
        // setTimeout(() => {
        //   window.location.href = "/login";
        // }, 3000);
      } catch (error) {
        console.error("Logout failed:", error);
        // setTimeout(() => {
        //   window.location.href = "/login";
        // }, 3000);
        window.location.href = "/login";
      }
    };

    logoutAndRedirect();
  }, [dispatch]);

  return (
    <>
      <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
        <div className="loaderWrapper-cart">
          <div className="loader"></div>
        </div>
      </div>
    </>
  );
};

export default Logout;
