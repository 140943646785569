import { useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/slice/userSlice";

const useLanguageURL = () => {
  let { user } = useSelector((store) => store.user);
  const { lang: _lang } = useParams();
  const currentLang = localStorage.getItem("i18nextLng");
  const lang = _lang || "sp";
  const navigate = useNavigate();
  const { pathname } = useLocation();
const dispatch = useDispatch()
  if (user?.language) {
    dispatch(setUser({...user,language:lang}));
    i18next.changeLanguage(user?.language);
  } else {
    if (lang === "en" || lang === "sp") {
      i18next.changeLanguage(lang);
    }
  }

  const languageInUrl = pathname.split("/")[1];

  useEffect(() => {
    if (!["en", "sp"].includes(languageInUrl)) {
      const modifiedUrl = `/${currentLang}${pathname}`;
      return navigate(modifiedUrl);
    }
  }, [pathname, currentLang, languageInUrl]);
};

export default useLanguageURL;