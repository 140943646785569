import React, { useRef, useState } from "react";
import { ScreenTopBanner } from "../components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Referral = () => {
  const { t } = useTranslation();

  let { user } = useSelector((user) => user.user);
  console.log("USER:", user);

  const referralTextRef = useRef(null);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    const textToCopy = referralTextRef.current.innerText;
    console.log("textToCopytextToCopy", textToCopy);

    navigator.clipboard
      // .writeText(`${window.location.hostname}/signup?referralCode=${textToCopy}`)
      .writeText(`${textToCopy}`)
      .then(() => {
        setIsCopied(true);
        //   console.log("Text copied to clipboard:", textToCopy);
      })
      .catch((err) => {
        setIsCopied(false);
        //   console.error("Error copying text to clipboard:", err);
      });

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <section className="container-fluid" id="jtc-token">
      <ScreenTopBanner title={t("titles.referral")} />
      <div className="referral-wrapper">
        <div className="referral-head">
          <h3>{t("titles.heading")}</h3>
          <p>{t("titles.policy")}</p>
          <p ref={referralTextRef}>{user?.referal_code}</p>
          <div className="referral-btn-wrapper">
            <button onClick={handleCopyClick}>{t("buttons.copy")}</button>
            {isCopied && (
              <div className="copy-referral">{t("titles.copied")}!</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Referral;
