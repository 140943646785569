import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { Link, useLocation } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import apis from "../../services";
import { setUser } from "../../redux/slice/userSlice";

const LocationModal = ({
  showLocSuccess,
  setShowLocSuccess,
  handleClick,
  isPending,
  handlePlaceSelect,
  handleAddress,
  selectedPlace,
  setSelectedPlace,
}) => {
  const { t, i18n } = useTranslation();

  let { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const language = localStorage.getItem("i18nextLng") || "sp";
  const languageUser = user?.language;
  console.log("languageUser", languageUser);
 
  
  const changeLangaugeConverter = async (lang) => {
    // console.log("LANGAUGE-lan", lang);
    try {
      const res = await apis.chnageLanguage({ language: lang });
      console.log("resLanguage", res);
      i18n.changeLanguage(lang);
      dispatch(setUser(res?.data?.data));
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleLanguageChange = async (value) => {
    try {

      localStorage.setItem("i18nextLng", value);
      i18n.changeLanguage(value);
  
      if (languageUser) {
        await changeLangaugeConverter(value); 
  
        // Construct the new URL path based on the selected language
        // let currentPath = location.pathname.replace(/^\/(en|sp)/, ""); 
        // let newPath = `/${value}${currentPath}`; 
  
        // Redirect to the updated path to reflect the language change in the URL
        // window.location.replace(newPath);
      }
    } catch (error) {
      console.error("Language change error", error);
    }
  };
  return (
    <Modal
      show={showLocSuccess}
      onHide={() => setShowLocSuccess(false)}
      centered
      size="lg"
      className="location-modal"
      backdrop="static"
      keyboard={false}
    >
      <div className="modal-content">
        <div className="lang-buttons">
          <div className="language-converter">
            <button
              className={`${language === "en" ? "active-lng" : ""}`}
              onClick={() => handleLanguageChange("en")}
            >
              EN
            </button>
            <button
              className={`${language === "sp" ? "active-lng" : ""}`}
              onClick={() => handleLanguageChange("sp")}
            >
              ES
            </button>
          </div>
        </div>
        <div
          className="cross-sign-container"
          onClick={() => setShowLocSuccess(false)}
        >

          <ImCross />

        </div>
        <div className="modal-body" bis_skin_checked="1">
          <img src="/assets/images/thankyou.png" alt="thankyou" />
          {/* <h3>Thankyou!</h3>
          <Link to="/">GO HOME</Link> */}
          <p>{t('descriptions.locDesc')}</p>
        </div>
        <div className="down">
          <div className="btn-wrapper-loc">
            <button onClick={handleClick}>
              {isPending ? t('buttons.loading') : t('buttons.useCurrentLoc')}
            </button>
          </div>
          <div className="input-wrapper">
            {/* <input
              type="text"
              name="location"
              id=""
              placeholder="Enter Manually"
            /> */}
            <GooglePlacesAutocomplete
              apiKey="AIzaSyBmaS0B0qwokES4a_CiFNVkVJGkimXkNsk"

              selectProps={{
                selectedPlace,
                onChange: setSelectedPlace,
                onSelect: handlePlaceSelect,
                placeholder: t('placeholders.address'),
              }}
            />
          </div>
          {/* <div className="location-submit-wrapper">
            <button >Submit</button>
          </div> */}
        </div>
      </div>
    </Modal>
  );
};

export default LocationModal;
