import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import apis from "../../services";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { setStores } from "../../redux/slice/StoreSlice";
import reduxApis from "../../redux/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";
import { setCart } from "../../redux/slice/productSlice";
const StoreLocatorModal = ({ storeLocator, setStoreLocator }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);
  const { stores } = useSelector((store) => store.store);

  console.log("checkStoreInRedux", stores);

  const [showStoreList, setShowStoreList] = useState(false);
  const [visibleStores, setVisibleStores] = useState(3);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [isStoreSelected, setIsStoreSelected] = useState(false);
  const modalRef = useRef();
  // GET STORELIST
  const fetchStoreList = (latitude, longitude) => {
    return apis.getNearestStoreList(latitude, longitude);
  };

  const {
    isLoading,
    error,
    data: { data: getStoreList } = {},
  } = useQuery({
    queryKey: ["getStoreList", lat, lon],
    queryFn: () => fetchStoreList(lat, lon),
    enabled: !!lat && !!lon && showStoreList,
    onError: (error) => {
      console.error("Error getting Store list:", error);
    },
  });

  // GET NEAREST STORE API
  const { mutate: IsNearestMutate, isPending } = useMutation({
    mutationFn: apis.getNearestStore,
    onError: function ({ message }) {
      console.log("MESSAGE ERROR:", message);
    },
    onSuccess: ({ data }) => {
      const nearestStore = data?.data?.[0]?.attributes;
      // console.log("nearestStore",nearestStore)

      if (data?.data?.length > 0 && nearestStore) {
        dispatch(setStores(nearestStore));

        dispatch(
          reduxApis.getAllProductWithCategory({
            userId: user?.id,
            store_id: nearestStore?.id,
          })
        );

        navigate("/");
        setStoreLocator(false);
      } else {
        toast.error("No nearest stores found at your current location.");
      }
    },
  });

  const handleSelectAutomatically = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          IsNearestMutate({
            lat: latitude,
            long: longitude,
          });
          setLocationError(null);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setLocationError(
              "Location access denied. Please enable location services in your browser settings."
            ); // Handle denied access
          } else {
            // console.log("Error getting location", error);
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const [loading, setLoading] = useState(false);

  const handleManualSelect = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLat(latitude);
          setLon(longitude);
          setShowStoreList(true);
          setLoading(true);

          fetchStoreList(latitude, longitude).then(() => {
            setLoading(false);
          });
          setLocationError(null);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            setLocationError(
              "Location access denied. Please enable location services in your browser settings."
            );
          } else {
            // console.error("Error getting location", error);
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleSelectStore = (storeObj) => {
    dispatch(setStores(storeObj?.attributes));
    if(storeObj?.attributes?.id !== stores?.id)
    {
      dispatch(
        reduxApis.getAllProductWithCategory({
          userId: user?.id,
          store_id: storeObj?.attributes?.id,
        })
      );
      dispatch(setCart(null));
      setShowStoreList(false);
      setIsStoreSelected(true);
      navigate("/");
      setStoreLocator(false);
    }else{
      setStoreLocator(false); 
    }
   
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setStoreLocator(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setStoreLocator]);

  return (
    <div>
      <Modal
        show={storeLocator}
        onHide={() => setStoreLocator(false)}
        // onHide={() => {
        //   if (!isStoreSelected) {
        //     toast.error("Please select a store before proceeding.");
        //   }
        // }}
        centered
        size="lg"
        className="storeLocatorModal"
        backdrop="static"
        keyboard={false}
      >
        <div
          onClick={() => {
            setStoreLocator(false);
          }}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            marginRight: "10px",
          }}
        >
          <IoCloseSharp size={"30px"} cursor={"pointer"} />
        </div>
        <Modal.Header>
          <Modal.Title>{t("titles.selectStore")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="img-con" style={{ display: "none" }}>
            <img src="/assets/images/infyom.png" alt="logo" />
          </div>
          {!showStoreList ? (
            <div className="btn-wrapper">
              {locationError && (
                <p className="error-message">{locationError}</p>
              )}{" "}
              {/* Display error message */}
              <button onClick={handleSelectAutomatically} disabled={isPending}>
                {isPending
                  ? t("titles.storeSearching")
                  : t("titles.selectAutomatically")}
              </button>
              <button onClick={handleManualSelect}>
                {t("titles.selectManually")}
              </button>
            </div>
          ) : (
            <div className="store-list">
              {loading ? (
                <div className="loaderWrapper-cart" style={{ height: "auto" }}>
                  <div className="loader"></div>
                </div>
              ) : (
                <ul>
                  {getStoreList?.data?.length > 0 ? (
                    getStoreList?.data?.slice(0, visibleStores).map((store) => (
                      <li key={store.id}>
                        <div className="store-item">
                          <div className="left">
                            <img src="/assets/images/store1.jpg" alt="store1" />
                          </div>
                          <div className="right">
                            <h3 className="store-name">
                              {store?.attributes?.name}
                            </h3>
                            <p className="store-location">
                              {store?.attributes?.address}
                            </p>

                            <button
                              className={`select ${
                                stores?.id === store.attributes.id
                                  ? "selected-store"
                                  : ""
                              }`}
                              onClick={() => handleSelectStore(store)}
                            >
                              {stores?.id === store.attributes.id
                                ? t("titles.selected")
                                : t("titles.select")}
                            </button>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>{t("titles.noStoreFound")}</li>
                  )}
                </ul>
              )}
              <div className="btn-bottom">
                {visibleStores < getStoreList?.data?.length && (
                  <button
                    onClick={() => setVisibleStores((prev) => prev + 3)}
                    className="show-more"
                  >
                    {t("buttons.showMore")}
                  </button>
                )}
                <button
                  onClick={() => setShowStoreList(false)}
                  className="back"
                >
                  {t("buttons.back")}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StoreLocatorModal;
