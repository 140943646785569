import axios from "axios";

const createBackendServer = (baseURL) => {
  const api = axios.create({
    baseURL: `${baseURL}`,
    withCredentials: false,
    headers: {
      Accept: "application/json",
    },
    timeout: 60 * 1000,
  });

  //Interceptor
  api.interceptors.request.use((config) => {
    const token = localStorage.getItem("token"); // Change 'your_token_key' to the actual key used in localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const message = error?.response?.data?.message;
      error.message = message ?? error.message;
      if (error?.response?.data?.errors)
        error.errors = error?.response?.data?.errors;
      if (error?.response?.status === 401) {
        // window.location.href = "/logout";
      }

      return Promise.reject(error);
    }
  );

  const headers = {
    "Content-Type": "multipart/form-data",
  };

  /*==========    GET REQUESTS    ==========*/

  const getProfile = async (id) => await api.get(`/api/user/${id}`);

  /*==========    POST REQUESTS JTC   ==========*/
  const authLogin = async (body) => api.post("ecomuser/login", body);

  const authLogout = async (body) => api.post("logout", body);

  const authVerify = async (body) => api.post("ecomuser/otp-verify", body);
  const authRegister = (body) => api.post("ecomuser/register", body);
  const authResend = (body) => api.post("ecomuser/resend-otp", body);
  const authForget = async (body) =>
    api.post("ecomuser/sendPasswordResetLinkEmailEcom", body);
  const authResetPassword = async (body) =>
    api.post("ecomuser/resetPasswordEcom", body);
  const authChangePassword = async (body) =>
    api.post("ecomuser/change-password", body);

  const cartCheckout = async (body) => api.post("ecomuser/cart/checkout", body);

  const removeProductFromCart = async (body) =>
    api.post("ecomuser/remove-cart", body);

  const updateProfile = async (body) =>
    api.post("ecommerceProfile/updateProfileEcom", body);
  const promo_Coupon = async (body) => api.post("ecomuser/apply-coupon", body);

  const addToCart = async (body) => api.post("ecomuser/cart", body);
  const clearCart = async () => api.post("ecomuser/clear-cart");

  const favouriteProduct = async (body) => api.post("updateFavourite", body);
  const orderConfirm = async (body) => api.post("ecomuser/orderConfirm", body);

  const authResendForgot = async (body) =>
    api.post("ecomuser/resend-otp-not-valid", body);

  const jtcTokenCheckout = async (body) =>
    api.post("buy-jtc-token-payment", body);

  const jtcTokenConfirm = async (body) =>
    api.post("buy-jtc-token-confirm", body);

  const newsFeed = async (body) => api.post("/cms/add-news-feed", body);

  const chnageLanguage = async (body) => api.post("change-language", body);

  /*==========    GET REQUESTS  JTC ==========*/

  const getAllProductWithCategory = async (body) => {
    console.log(body, "bodybody");
    let res = await api.get(
      `getProductCategory?userId=${body?.userId}&store_id=${body?.store_id}`
    );
    return res;
  };

  const getProductDetails = async (slug_en, userId) => {
    if (!slug_en) {
      console.error("Slug is missing.");
      return;
    }

    return await api.get(`product/showbyslug/${slug_en}?user_id=${userId}`);
  };

  // const getViewCart = async (lat,long,type) => await api.get(`ecomuser/view-cart`);
  const getViewCart = async (lat, long, type) => {
    const params = {
      lat: lat,
      lng: long,
      order_type: type,
    };

    return await api.get("ecomuser/view-cart", { params });
  };
  const getFavouriteProduct = async () => await api.get(`getFavourite`);
  const getOrderHistory = async () => await api.get(`orderHistory`);
  const getCoupon = async () => await api.get(`couponUser`);
  const getReferral = async () => await api.get(`referralApi`);
  const getUserWallet = async () => await api.get(`userWallet`);
  const getStoreDiscountBanner = async () => await api.get(`storeDiscount`);
  const getNotify = async (pageSize, pageNumber) =>
    await api.get(`notification?page_size=${pageSize}&page=${pageNumber}`);
  const getJtcToken = async () => await api.get(`tokenPlan`);

  const authRegisterReferral = ({ body, code }) =>
    api.post(`auth/register?referralCode=${code}`, body);
  const updateUser = ({ id, body }) => api.put(`user/update/${id}`, body);
  const create_booking = async (body) => api.post("booking", body);
  const confirmBooking = async (body) => api.post(`booking/confirmed`, body);
  const giveRating = async (body) => api.post(`rating`, body);
  const authUpdatePassword = async (body) =>
    api.put("auth/update-password", body);

  const getStores = async (id) => api.get(`/store?user_Id=${id}`);

  const getStoreDetails = async (id) => api.get(`store/slug/${id}?type=store`);
  const getUserBookings = async (id) => api.get(`booking/user/${id}`);
  const cancelBooking = async (body) => api.post(`booking/cancelled`, body);

  const getStoreSlot = ({ id, duration, staff_Id }) =>
    api.get(`store/slots/${id}?duration=${duration}&staff_Id=${staff_Id}`);

  const getStoreSlotwithoutStaff = ({ id, duration }) =>
    api.get(`store/slots/${id}?duration=${duration}
    `);
  const addFavorite = (body) => api.post(`user/favourite-add`, body);
  const searchSalon = (body) => api.post(`store/search`, body);
  const generateUserReferral = (id) => api.post(`user/referral/${id}`);
  const getUserReferral = (id) => api.get(`user/referral/${id}`);

  const getOrderDetails = (id) => api.get(`/getSalesApp/${id}`);

  const getStoresList = async () => await api.get(`storesList`);
  const getNearestStore = async (body) =>
    await api.post(`getNearestStores`, body);

  const getNearestStoreList = async (lat,lon) => api.get(`get-nearest-store?lat=${lat}&lon=${lon}`);


  //Returning all the API
  return {
    getStoreSlot,
    getStoreDetails,
    getStores,
    authLogin,
    authVerify,
    authRegister,
    authRegisterReferral,
    authForget,
    authUpdatePassword,
    getProfile,
    create_booking,
    confirmBooking,
    getStoreSlotwithoutStaff,
    getUserBookings,
    cancelBooking,
    addFavorite,
    getUserReferral,
    updateUser,
    searchSalon,
    generateUserReferral,
    giveRating,
    authResend,
    authResetPassword,
    getAllProductWithCategory,
    getProductDetails,
    addToCart,
    getViewCart,
    cartCheckout,
    authLogout,
    clearCart,
    favouriteProduct,
    getFavouriteProduct,
    getOrderHistory,
    getCoupon,
    authChangePassword,
    getReferral,
    getUserWallet,
    getStoreDiscountBanner,
    updateProfile,
    removeProductFromCart,
    orderConfirm,
    authResendForgot,
    getNotify,
    getJtcToken,
    jtcTokenCheckout,
    jtcTokenConfirm,
    newsFeed,
    getOrderDetails,
    promo_Coupon,
    chnageLanguage,
    getStoresList,
    getNearestStore,
    getNearestStoreList
  };
};

const apis = createBackendServer(process.env.REACT_APP_SERVER_URL);

export default apis;
