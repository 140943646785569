import { useQuery } from "@tanstack/react-query";
import React from "react";
import apis from "../services";
import { Link } from "react-router-dom";
import useNavigate from "../helper/useNavigate";

import moment from "moment";
import { useTranslation } from "react-i18next";
import detectLang from "../helper/detectLanguage";

const Order = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const Navigate = useNavigate();
  const handleTimerComponent = (orderId) => {
    Navigate(`/order-status/${orderId}`);
  };

  const {
    isLoading,
    error,
    refetch,
    data: { data: getOrderHistory } = {},
  } = useQuery({
    queryKey: ["getOrderHistory"],
    queryFn: () => apis.getOrderHistory(),
    onError: (error) => {
      console.error("Error geting Order History:", error);
      // toast.error("Failed to fetch products. Please try again later.");
    },
  });
  console.log("getOrderHistory", getOrderHistory);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];
    const formattedTime = date.toTimeString().split(" ")[0].slice(0, 5);

    return `${formattedDate} `;
  };

  const checkTime = (start_time, end_time) => {
    const currentTime = moment();
    return currentTime.isBetween(
      moment(start_time, "h:mm A"),
      moment(end_time, "h:mm A")
    );
  };

  return (
    <>
      {isLoading ? (
        <>
          <div className="loaderWrapper-cart">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <section className="container-fluid" id="jtc-token">
          <div className="heading-bg">
            <h2>{t("titles.order")}</h2>
          </div>
          <div id="order-card-parent">
            {getOrderHistory?.data.length > 0 ? (
              getOrderHistory?.data.reverse().map((data, index) => {
                // console.log("DATA:", data);
                return (
                  <>
                    <div className="order-card" key={index}>
                      <div className="left">
                        <div className="img-con">
                          <img src="/assets/images/Frame.png" alt="product" />
                        </div>
                        <div className="text-con">
                          <p>
                            {t("labels.orderID")}:{data?.id}
                          </p>
                          <div className="one">
                            {/* <p>
                      QTY: <span>02</span>
                    </p> */}
                          </div>
                          <div className="product-name-addons">
                            {data?.sale_items_id.map((saleItem, index) => {
                              return (
                                <div key={index} className="wrapper">
                                  {console.log("saleItem", saleItem)}
                                  <div className="product-name">
                                    <h5>
                                      {" "}
                                      {detectLang(
                                        saleItem?.product,
                                        "name",
                                        lang
                                      )}
                                    </h5>

                                    <h5>
                                      {data?.type != "COINS" && "$"}{" "}
                                      {Number(saleItem?.product_price) *
                                        Number(saleItem?.quantity)}
                                    </h5>
                                  </div>
                                  {saleItem?.product_variation && (
                                    <h4 className="quantity">
                                      <span>
                                        {t("titles.product_variation")}
                                      </span>{" "}
                                      <span>{saleItem?.product_variation}</span>
                                    </h4>
                                  )}{" "}
                                  <h4 className="quantity">
                                    <span>{t("titles.quantity")}:</span>{" "}
                                    {saleItem?.quantity}
                                  </h4>
                                  <div className="addons-con">
                                    {saleItem?.sale_item_addons.map(
                                      (addons, index) => {
                                        return (
                                          <h6 key={index} className="addons">
                                            {addons.value}
                                          </h6>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <p className="order-date-text">
                            {t("labels.date")}:{" "}
                            {moment(data?.created_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </p>
                          <p className="order-date-text">
                            <span>{t("titles.tax")}:</span> 
                            <span>{data?.tax}</span>
                          </p>
                          <p className="order-date-text">
                            <span>{t("titles.type")}:</span>
                            <span> {data?.type}</span>
                          </p>
                          {data?.order_type == "delivery" && (
                            <p className="order-date-text">
                              <span>{t("titles.delivery_charges")}:</span> 
                              <span>{data?.shipping}</span>
                            </p>
                          )}
                          <p className="order-date-text">
                            <span>{t("titles.order_type")}:</span> 
                            <span>{data?.order_type}</span>
                          </p>

                          <h6 className="order-date-text mt-2">
                            <span>{t("titles.grandTotal")}:</span>
                            <span>{data?.type != "COINS" && "$"} {data?.grand_total}</span>
                          </h6>
                        </div>
                      </div>
                      <div className="right">
                        <button
                          className={`preparing ${
                            data?.order_status == 4 ? "dispatched" : "pending"
                          }`}
                        >
                          {data?.order_status == 0
                            ? t("status.pending")
                            : data?.order_status == 1
                            ? t("status.acceptedByJtc")
                            : data?.order_status == 2
                            ? t("status.preparing")
                            : data?.order_status == 3
                            ? t("status.delivered")
                            : data?.order_status == 4
                            ? t("status.completed")
                            : data?.order_status == 5
                            ? t("status.returned")
                            : data?.order_status == 6
                            ? t("status.refunded")
                            : data?.order_status == 7
                            ? t("status.cancelled")
                            : data?.order_status == 8
                            ? t("status.hold")
                            : data?.order_status == 9
                            ? t("status.startDelivery")
                            : data?.order_status == 10
                            ? t("status.riderArrived")
                            : data?.order_status == 11
                            ? t("status.riderPickUpOrder")
                            : data?.order_status == 12
                            ? t("status.qualityChecked")
                            : t("status.processing")}
                        </button>
                      </div>
                    </div>

                    <div className="btn-order-status">
                      <button onClick={() => handleTimerComponent(data?.id)}>
                        {t("buttons.orderStatus")}
                      </button>
                    </div>
                  </>
                );
              })
            ) : (
              <p className="no-order">{t("messages.noOrderFound")}</p>
            )}
            {/* <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="preparing">Preparing</button>
          </div>
        </div> */}
            {/* <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="done">Done</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="dispatched">Dispatched</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="done">Done</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="reject">Reject</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="dispatched">Dispatched</button>
          </div>
        </div>
        <div className="order-card">
          <div className="left">
            <div className="img-con">
              <img src="/assets/images/Frame.png" alt="product" />
            </div>
            <div className="text-con">
              <p>235asdasio1564as</p>
              <div className="one">
                <p>
                  QTY: <span>02</span>
                </p>
                <p>$09.00</p>
              </div>
            </div>
          </div>
          <div className="right">
            <button className="preparing">Preparing</button>
          </div>
        </div> */}
          </div>
        </section>
      )}
    </>
  );
};

export default Order;
