import * as Yup from "yup";

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/;

export const SignupValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),

  last_name: Yup.string().required("Last Name is required"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),

  phone_no: Yup.number()
    .required("Phone Number is required"),

  password: Yup.string()
    .matches(passwordRegex, "Password must be at least 8 characters long, and include at least one uppercase letter, one lowercase letter, one number, and one special character.")
    .required("Password is required"),

  confirm_password: Yup.string()
    .matches(passwordRegex, "Confirm Password must match the same criteria as Password.")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});


export const ForgotValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const ConfirmPasswordReset = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),

  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password_confirmation"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

// export const CartCheckout = Yup.object().shape({
//   // address: Yup.string(),
//   order_type: Yup.string().required("Order type is required"),
//   // longitude: Yup.string().required("Longitude  is required"),
//   // latitude: Yup.string(),
//   promo_code: Yup.string(),

//   nearest_address: Yup.string().required('Nearest Address is Required'),
//   nearest_flatno: Yup.string().required('Nearest Flat Number is Required'),
//   table_number: Yup.string().required('Table Number is Required'),

//   // nearest_address: Yup.string().when('order_type', {
//   //   is: 'delivery',
//   //   then: Yup.string().required("Nearest address is required"),
//   //   otherwise: Yup.string(),
//   // }),
//   // nearest_flatno: Yup.string().when('order_type', {
//   //   is: 'delivery',
//   //   then: Yup.string().required("Nearest flatno/residency is required"),
//   //   otherwise: Yup.string(),
//   // }),
// });

export const commonSchema = Yup.object().shape({
  order_type: Yup.string().required("Order type is required"),
  promo_code: Yup.string(),
});

// Delivery validation schema
const deliverySchema = Yup.object().shape({
  nearest_address: Yup.string().required("Nearest Address is Required"),
  nearest_flatno: Yup.string().required("Nearest Flat Number is Required"),
});

// Dine-in validation schema
export const dineInSchema = Yup.object().shape({
  table_number: Yup.string().required("Table Number is Required"),
});
export const getValidationSchema = (orderType) => {
  switch (orderType) {
    case "delivery":
      return commonSchema.concat(deliverySchema);
    case "dine-in":
      return commonSchema.concat(dineInSchema);
    default:
      return commonSchema;
  }
};

export const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required("Current password is required"),
  new_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("New password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match")
    .required("Please confirm your new password"),
});
