import { createSlice } from "@reduxjs/toolkit";

const storeSlice = createSlice({
  name: "user",
  initialState: {
    stores: [],
  },
  reducers: {
    setStores: (state, action) => {
      console.log("state, action", state, action);
      state.stores = action.payload;
    },
    clearStores: (state) => {
      state.stores = [];
    },
  },
});

export const { setStores, clearStores } = storeSlice.actions;

export default storeSlice.reducer;
